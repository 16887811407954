.title{
  font-size: 40px;
  font-family: sans-serif;
  font-weight: 600;
  align-self: flex-start;
  margin-bottom: 20px;

}

.subTitle{
  font-size: 30px;
  font-family: sans-serif;
  font-weight: 600;

}

.center{
  display: flex;
  justify-content: center;
}

.info{
  font-size: 18px;
  font-family:sans-serif;
  margin-bottom: 3px;
}


.nocopy {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.minus{
  background-color: #ffb0b7
}

.plus{
  background-color: #d1e7dd;
}